import { useEffect, useState } from "react";
// @mui
import { Box, Link as MuiLink } from "@mui/material";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { usePage } from "@inertiajs/react";
import { useSnackbar } from "notistack";
import Header from "./Header/Header";
import useResponsive from "@/hooks/useResponsive";
import NavVertical from "./nav/navVertical";
import Ably from "ably/promises";

export default function AuthenticatedLayout({ children }) {
    const {
        auth: { user },
        flash,
        reminder = [],
    } = usePage().props;
    const [notifications, setNotifications] = useState(user?.notifications);
    const { enqueueSnackbar } = useSnackbar();
    const [anchorEl, setAnchorEl] = useState(null);
    const isDesktop = useResponsive("up", "lg");
    const isTab = useResponsive("up", "md");
    const isMobile = useResponsive("up", "sm");
    const [open, setOpen] = useState(false);
    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    // console.log(user);
    const renderNavVertical = (
        <NavVertical openNav={open} onCloseNav={handleClose} />
    );

    useEffect(() => {
        const ably = new Ably.Realtime.Promise({
            key: "VIpnQg.BpSDLQ:IVdEk5nuOhDmdRPJB3fs1Nuicbiv34XOkBwvbvkLRCM",
            clientId: user.id.toString(),
        });
        const channel = ably.channels.get(`private:user.${user.id}`);

        channel.subscribe("message", (message) => {
            enqueueSnackbar(`${message.data.name} messaged you.`);
            // setNotifications((prevNotifs) => [...prevNotifs, message]);
        });

        return () => {
            channel.unsubscribe();
        };
    }, [user]);

    useEffect(() => {
        if (flash.message) {
            enqueueSnackbar(flash.message, {
                variant: flash.type || "default",
            });
            flash.message = null;
        }
    }, [flash]);

    useEffect(() => {
        if (reminder && user) {
            const reminders = reminder.filter((rem) => rem?.message);
            (reminders || [])?.forEach((rem) => {
                enqueueSnackbar(rem.message, {
                    variant: rem.type || "default",
                    preventDuplicate: true,
                    autoHideDuration: 9000,
                    anchorOrigin: {
                        vertical: "top",
                        horizontal: "center",
                    },
                });
            });
        }
    }, [reminder, user]);

    return (
        <Stack>
            {/* Header */}
            <Header
                user={user}
                notifications={notifications}
                onOpenNav={handleOpen}
            />
            {/* END HEADER */}
            <Box
                sx={{
                    display: { lg: "flex" },
                    minHeight: { lg: 1 },
                }}
            >
                <Box
                    component="main"
                    sx={{
                        marginTop: isDesktop ? "100px" : "80px",
                        paddingX: 0,
                        width: {
                            xs: "100%",
                        },
                    }}
                >
                    {children}
                    {/* FOOTER */}
                    <Stack
                        alignItems={"center"}
                        sx={{
                            borderTop: "1px solid #cddfff",
                            transition: "all 0.3s",
                            mt: 10,
                        }}
                    >
                        <Typography
                            sx={{
                                marginTop: 2,
                                fontFamily: "Nunito, sans-serif",
                            }}
                        >
                            <Typography
                                variant="body2"
                                component="span"
                                sx={{ fontFamily: "Nunito, sans-serif" }}
                            >
                                © Copyright 2023 |{" "}
                                <Typography
                                    component="span"
                                    sx={{
                                        fontWeight: 700,
                                        fontFamily: "Nunito, sans-serif",
                                    }}
                                >
                                    Upcare
                                </Typography>
                                . | All Rights Reserved
                            </Typography>
                        </Typography>
                        <Typography
                            variant="body2"
                            sx={{
                                marginBottom: 3,
                                fontFamily: "Nunito, sans-serif",
                            }}
                        >
                            Designed by{" "}
                            <MuiLink href="https://infinityhub.com/">
                                InfinityHub
                            </MuiLink>
                        </Typography>
                    </Stack>
                    {/* END FOOTER */}
                </Box>
            </Box>
        </Stack>
    );
}
